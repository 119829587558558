<template>
  <v-card rounded>
    <v-card-title>
      <v-row no-gutters>
        <div>Tạo order</div>
        <v-spacer />
        <div class="">
          <v-btn
            :disabled="isLoading"
            text
            class="mr-2"
            @click="onCloseDialog"
            >Đóng</v-btn
          >
          <v-btn
            :disabled="isLoading"
            :loading="isLoading"
            color="primary"
            @click="onCreateOrder"
            >Tạo</v-btn
          >
        </div>
      </v-row>
    </v-card-title>

    <v-card-text class="py-6">
      <PortPicker
        :value="selectedPortCode"
        @change="onSelectPort"
        auto-select-first
      />
      <CustomerServicePicker
        :value="selectedCustomerServiceId"
        @change="onSelectCustomerService"
        auto-select-first
      />
    </v-card-text>
  </v-card>
</template>

<script>
import PortPicker from '@/components/shared/local/vpl/PortPicker.vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import CustomerServicePicker from '@/components/shared/local/vpl/CustomerServicePicker.vue';

export default {
  components: { PortPicker, CustomerServicePicker },
  props: {},
  data: () => ({
    isLoading: false,
    selectedPortCode: null,
    selectedCustomerServiceId: null,
  }),
  computed: {
    ...mapState('vpl/order', ['orderList']),
  },
  async created() {},
  watch: {},
  methods: {
    ...mapActions('vpl/order', ['createOrder', 'fetchOrderList']),
    onCloseDialog() {
      this.$emit('close-dialog');
    },
    onSelectPort(item) {
      this.selectedPortCode = item.code;
    },
    onSelectCustomerService(item) {
      this.selectedCustomerServiceId = item.id;
    },
    async onCreateOrder() {
      try {
        this.isLoading = true;
        const form = {
          portCode: this.selectedPortCode,
          customerServiceId: this.selectedCustomerServiceId,
          userNote: '',
          attachments: [],
        };
        await this.createOrder(form);
        await this.fetchOrderList();
        this.$router.push(`/app/port/my-order/${this.orderList[0].id}`);
        this.$emit('close-dialog');
        this.$store.dispatch('ui/showSnackbar', {
          message: 'Tạo order thành công',
          color: 'success',
        });
      } catch (error) {
        console.log('[LOG] : error', error);
        if (error?.response?.data?.message) {
          this.$store.dispatch('ui/showSnackbar', {
            message: error.response.data.message,
            color: 'error',
          });
        } else {
          this.$store.dispatch('ui/showSnackbar', {
            message: 'Có lỗi xảy ra',
            color: 'error',
          });
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
