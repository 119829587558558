<template>
  <v-card rounded>
    <v-card-title>
      <v-row no-gutters>
        <div>Xóa order</div>
        <v-spacer />
        <div class="">
          <v-btn
            :disabled="isLoading"
            text
            class="mr-2"
            @click="onCloseDialog"
            >Đóng</v-btn
          >
          <v-btn
            :disabled="isLoading"
            :loading="isLoading"
            color="error"
            @click="onDeleteOrder"
            >Xóa</v-btn
          >
        </div>
      </v-row>
    </v-card-title>

    <v-card-text class="pb-6 pt-2">
      Bạn có chắc chắn muốn xóa order này không?
    </v-card-text>
  </v-card>
</template>

<script>
import PortPicker from '@/components/shared/local/vpl/PortPicker.vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import CustomerServicePicker from '@/components/shared/local/vpl/CustomerServicePicker.vue';

export default {
  components: { PortPicker, CustomerServicePicker },
  props: {
    item: {
      type: Object,
    },
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {},
  async created() {},
  watch: {},
  methods: {
    ...mapActions('vpl/order', ['deleteOrder', 'fetchOrderList']),
    onCloseDialog() {
      this.$emit('close-dialog');
    },
    async onDeleteOrder() {
      try {
        this.isLoading = true;
        await this.deleteOrder({
          id: this.item.id,
        });
        await this.fetchOrderList();
        this.$message.success('Xoá order thành công');
        this.$emit('close-dialog');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
