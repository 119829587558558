<template>
  <v-autocomplete
    :value="value"
    :items="list"
    :loading="isLoading"
    :disabled="isLoading"
    :clearable="clearable"
    label="Chọn cảng"
    prepend-inner-icon="mdi-anchor"
    type="text"
    outlined
    item-text="name"
    item-value="code"
    return-object
    @change="onChange"
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

export default {
  components: {},
  props: {
    value: {
      type: String,
    },
    autoSelectFirst: {
      type: Boolean,
      default: false,
      required: false,
    },
    clearable: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    ...mapState('vpl/port', { list: 'portList' }),
  },
  async created() {
    try {
      this.isLoading = true;
      await this.fetchList();
      if (!this.value && this.autoSelectFirst) {
        this.$emit('change', this.list[0]);
      }
    } catch (error) {
      console.log('[LOG] : error', error);
    } finally {
      this.isLoading = false;
    }
  },
  watch: {},
  methods: {
    ...mapActions('vpl/port', { fetchList: 'fetchPortList' }),
    onChange(value) {
      return this.$emit('change', value);
    },
  },
};
</script>

<style></style>
