var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", { staticClass: "display-1 mt-2 mb-5 text-uppercase" }, [
        _vm._v("DANH SÁCH ORDER"),
      ]),
      _c(
        "v-card",
        {
          staticClass: "pa-8",
          attrs: {
            "min-height": "400",
            ripple: false,
            width: "100%",
            rounded: "lg",
          },
        },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialog.orderCreate = true
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-plus")]),
                  _vm._v(" Tạo order "),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {},
            [
              _c(
                "v-card",
                {
                  staticClass: "mt-6",
                  attrs: { elevation: "0", rounded: "xl" },
                },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "font-weight-bold",
                              attrs: { cols: "2" },
                            },
                            [_vm._v(" Mã Order ")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "font-weight-bold",
                              attrs: { cols: "2" },
                            },
                            [_vm._v(" Ngày tạo ")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "font-weight-bold",
                              attrs: { cols: "2" },
                            },
                            [_vm._v(" Cảng ")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "font-weight-bold",
                              attrs: { cols: "3" },
                            },
                            [_vm._v(" Dịch vụ ")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "font-weight-bold",
                              attrs: { cols: "2" },
                            },
                            [_vm._v(" Trạng thái ")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "font-weight-bold",
                              attrs: { cols: "1" },
                            },
                            [_vm._v(" Thao tác ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._l(_vm.orderList, function (item) {
                return _c(
                  "v-card",
                  {
                    key: item.id,
                    staticClass: "mt-2",
                    staticStyle: { cursor: "pointer" },
                    attrs: {
                      color: "background",
                      elevation: "0",
                      rounded: "xl",
                      ripple: false,
                      to: { path: "/app/port/my-order/" + item.id },
                    },
                    on: {
                      click: function ($event) {
                        return _vm.onViewOrder(item)
                      },
                    },
                  },
                  [
                    _c(
                      "v-card-text",
                      [
                        _c(
                          "v-row",
                          {
                            staticClass: "align-center",
                            attrs: { "no-gutters": "" },
                          },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "font-weight-bold primary--text text-truncate",
                                attrs: { cols: "2" },
                              },
                              [_vm._v(" " + _vm._s(item.code) + " ")]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "text-truncate",
                                attrs: { cols: "2" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.formatDate(item.createdAt)) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "text-truncate",
                                attrs: { cols: "2" },
                              },
                              [_vm._v(" " + _vm._s(item.port.name) + " ")]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "text-truncate",
                                attrs: { cols: "3" },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(item.customerService.name) + " "
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "text-truncate",
                                attrs: { cols: "2" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.formatStatus(item.status)) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "text-truncate",
                                attrs: { cols: "1" },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      disabled: !_vm.checkCanDeleteOrder(item),
                                      icon: "",
                                      color: "error",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.onClickDeleteOrder(item)
                                      },
                                    },
                                  },
                                  [_c("v-icon", [_vm._v("mdi-delete")])],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _vm.isLoading
                ? _vm._l(5, function (item, i) {
                    return _c(
                      "v-card",
                      {
                        key: i,
                        staticClass: "mt-2",
                        staticStyle: { cursor: "pointer" },
                        attrs: {
                          color: "background",
                          elevation: "0",
                          rounded: "xl",
                        },
                      },
                      [
                        _c(
                          "v-card-text",
                          [
                            _c("v-skeleton-loader", {
                              attrs: { boilerplate: false, type: "text@2" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  })
                : _vm._e(),
              !_vm.isLoading && _vm.orderList.length === 0
                ? _c("NoData")
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "550px" },
          model: {
            value: _vm.dialog.orderCreate,
            callback: function ($$v) {
              _vm.$set(_vm.dialog, "orderCreate", $$v)
            },
            expression: "dialog.orderCreate",
          },
        },
        [
          _vm.dialog.orderCreate
            ? _c("OrderCreate", {
                on: {
                  "close-dialog": function ($event) {
                    _vm.dialog.orderCreate = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "550px" },
          model: {
            value: _vm.dialog.orderDelete,
            callback: function ($$v) {
              _vm.$set(_vm.dialog, "orderDelete", $$v)
            },
            expression: "dialog.orderDelete",
          },
        },
        [
          _vm.dialog.orderDelete
            ? _c("OrderDelete", {
                attrs: { item: _vm.selectedOrder },
                on: {
                  "close-dialog": function ($event) {
                    _vm.dialog.orderDelete = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }