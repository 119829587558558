var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-autocomplete", {
    attrs: {
      value: _vm.value,
      items: _vm.list,
      loading: _vm.isLoading,
      disabled: _vm.isLoading,
      clearable: _vm.clearable,
      label: "Chọn cảng",
      "prepend-inner-icon": "mdi-anchor",
      type: "text",
      outlined: "",
      "item-text": "name",
      "item-value": "code",
      "return-object": "",
    },
    on: { change: _vm.onChange },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }