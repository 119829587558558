var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { rounded: "" } },
    [
      _c(
        "v-card-title",
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("div", [_vm._v("Tạo order")]),
              _c("v-spacer"),
              _c(
                "div",
                {},
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: { disabled: _vm.isLoading, text: "" },
                      on: { click: _vm.onCloseDialog },
                    },
                    [_vm._v("Đóng")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.isLoading,
                        loading: _vm.isLoading,
                        color: "primary",
                      },
                      on: { click: _vm.onCreateOrder },
                    },
                    [_vm._v("Tạo")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "py-6" },
        [
          _c("PortPicker", {
            attrs: { value: _vm.selectedPortCode, "auto-select-first": "" },
            on: { change: _vm.onSelectPort },
          }),
          _c("CustomerServicePicker", {
            attrs: {
              value: _vm.selectedCustomerServiceId,
              "auto-select-first": "",
            },
            on: { change: _vm.onSelectCustomerService },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }